import React from 'react';
import ReactDOM from 'react-dom';
import { RecoilRoot } from 'recoil';
import Modal from 'react-modal';
import App from './App';
import { HelmetProvider } from 'react-helmet-async';
import './i18n';

Modal.setAppElement('#root');

ReactDOM.render(
  <React.StrictMode>
      <RecoilRoot>
        <HelmetProvider>
          <App />
        </HelmetProvider>
      </RecoilRoot>
  </React.StrictMode>,
  document.getElementById('root'),
);
