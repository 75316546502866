import React, { CSSProperties, useEffect } from 'react';
import { css } from 'styled-components';
import OptimisedImage from './OptimisedImage';


const BackgroundImage: React.FC<{
  imageName: string;
  imageType: 'png' | 'jpg' | 'jpeg';
  heightFlag: boolean;
  hasFilter:boolean;
  noScroll:boolean;
}> = ({ children, imageName, imageType, heightFlag, hasFilter, noScroll}) => {
  const [filterImage, setFilter] = React.useState('brightness(0.8) contrast(0.9) saturate(0.9)');
  useEffect(() => {
    if(!hasFilter) {
      setFilter('none');
    }
  }, [])
  const styles = {
    slide: {
      minHeight: '100vh',
      overflow: 'hidden',
      width: '100%',
      writable: true,
      maxHeight: `${noScroll && '100vh'}`
    } as CSSProperties,
    background: {
      minWidth: '100vw',
      minHeight: '100vh',
      width: '90%',
      objectFit: 'cover',
      zIndex: -1,
      filter: filterImage,
      writable: true,
      maxHeight: `${noScroll && '100vh'} `
      
    } as CSSProperties,
    backgroundContainer: {
      zIndex: -1,
      minHeight: '100% !important',
      minWidth: '100% !important',
      width: '90vw',
      writable: true,
      maxHeight: `${noScroll && '100vh'} `
    } as CSSProperties,
    textInfoContainer: {
      flex: 1,
      backgroundColor: 'rgba(0, 0, 0, 0.25)',
      borderRadius: 20,
    } as CSSProperties,
    heightInProcent: {
      height: 'max(100%, 900px)',
      writable: true,
    } as CSSProperties,
    heightInVh: {
      height: 'max(100vh, 900px)',
      writable: true,
    } as CSSProperties,
    posAbs: {
      position: 'absolute',
    } as CSSProperties,
    posFixed: {
      position: 'fixed',
    } as CSSProperties,
  };
  return <div
    style={
      heightFlag ? { ...styles.slide, ...styles.heightInVh} : { ...styles.slide, ...styles.heightInProcent}
      
    }
  >
    <div
      style={
        heightFlag
          ? { ...styles.backgroundContainer, ...styles.heightInVh, ...styles.posAbs }
          : { ...styles.backgroundContainer, ...styles.heightInProcent, ...styles.posFixed }
      }
    >
      <OptimisedImage
        name={imageName}
        type={imageType}
        style={
          heightFlag
            ? { ...styles.background, ...styles.heightInVh }
            : { ...styles.background, ...styles.heightInProcent }
        }
        alt="backgroundImage"
      />
    </div>
    {children}
  </div>
};
export default BackgroundImage;
