import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';
import { IProfileInformation } from '../types/profile.types';

const { persistAtom } = recoilPersist();

export const userState = atom<IProfileInformation>({
  key: 'userDataState',
  default: {
    id: 0,
    code_force_name: '',
    email: '',
    infoarena_name: '',
    name: '',
    pbinfo_name: '',
    phone_number: '',
    discord: '',
    teacher: 0,
    lessons: [],
    courses: 0,
    language: '',
  },
});

export const isLoggedIn = atom({
  key: 'isLoggedIn',
  default: false,
  effects_UNSTABLE: [persistAtom],
});
