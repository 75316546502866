import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { Link, useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { isScrolledState } from '../../atoms/scrolled';
import { whiteNavbar } from '../../utils';

const NavbarLink: React.FC<{
  label: string;
  to: string;
}> = ({ label, to }) => {
  const isScrolled = useRecoilValue(isScrolledState);
  const location = useLocation();
  const isMobile = useMediaQuery({
    query: '(max-width: 500px)',
  });

  return (
    <Link to={`/${to}`}>
      <button type="button" className={`navbar-link ${whiteNavbar(isScrolled, location, isMobile) ? 'navbar-link-scrolled' : ''}`}>
        {label}
      </button>
    </Link>
  );
};

export default NavbarLink;
