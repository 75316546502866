import Cookies from 'js-cookie';
import { makeRequest } from '../utils';

export const logout = async () => {
  await makeRequest({
    url: '/api/auth/logout',
    method: 'POST',
  });
  Cookies.remove('session');
  window.location.reload();
  return true;
};

type TokenCredentials = {
  token: string;
};

export const tokenLogin = async (credentials: TokenCredentials) => {
  if (!credentials || !credentials.token) {
    throw new Error('Some fields are missing.');
  }

  const { data, error } = await makeRequest({
    url: '/api/auth/token_login',
    data: credentials,
    method: 'POST',
  });

  if (error) {
    throw new Error(error);
  } else {
    return {
      success: true,
      data,
    };
  }
}

type Credentials = {
  email: string;
  password: string;
};

export const login = async (credentials: Credentials) => {
  if (!credentials || !credentials.email || !credentials.password) {
    throw new Error('Some fields are missing.');
  }

  const { data, error } = await makeRequest({
    url: '/api/auth/login',
    data: credentials,
    method: 'POST',
  });

  if (error) {
    throw new Error(error);
  } else {
    return {
      success: true,
      data,
    };
  }
};

type RegisterCredentials = {
  email: string;
  password: string;
  name: string;
  phoneNumber: string;
  referred_by?: number;
};

export const register = async (details: RegisterCredentials) => {
  if (!details || !details.name || !details.email || !details.password || !details.name) {
    throw new Error('Some fields are missing.');
  }

  if (Number.isNaN(details.referred_by)) {
    delete details.referred_by;
  }

  const { data, error } = await makeRequest({
    url: '/api/auth/register',
    data: details,
    method: 'POST',
  });

  if (error) {
    throw new Error(error);
  } else {
    return {
      success: true,
      data,
    };
  }
};

type ForgotCredentials = {
  email: string;
};

export const forgotPassword = async (credentials: ForgotCredentials) => {
  const { data, error } = await makeRequest({
    url: '/api/auth/forgot',
    data: credentials,
    method: 'POST',
  });

  if (error) {
    throw new Error(error);
  } else {
    return {
      success: true,
      data,
    };
  }
};
