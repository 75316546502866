import i18n, { Resource } from 'i18next';
import { initReactI18next } from 'react-i18next';
import { i18nextPlugin } from 'translation-check';
import englishTrans from './translations/translation_en.json';
import romanianTrans from './translations/translation_ro.json';
import { isRomanianWebsite } from './utils';

const resources: Resource = {
  ro: {
    translation: romanianTrans,
  },
  en: {
    translation: englishTrans,
  },
};

i18n.use(i18nextPlugin).use(initReactI18next).init({
  resources,
  lng: isRomanianWebsite ? 'ro' : 'en',
  debug: false,
});

export default i18n;
