import React from 'react';
import { Button, Row } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { useRecoilValue} from 'recoil';
import { useTranslation } from 'react-i18next';
import { isLoggedIn, userState } from '../../atoms/auth';
import { logout } from '../../api/auth/utils';
import NavbarLink from './NavbarLink';
import { whiteNavbar } from '../../utils';
import { isScrolledState } from '../../atoms/scrolled';
import { useMediaQuery } from 'react-responsive';

const DesktopNavbar = () => {
  const loggedIn = useRecoilValue(isLoggedIn);
  const profileInformation = useRecoilValue(userState);
  const { t } = useTranslation('', { keyPrefix: 'navbar' });

  const isScrolled = useRecoilValue(isScrolledState);
  const location = useLocation();
  const isMobile = useMediaQuery({
    query: '(max-width: 500px)',
  });
  
  return (
    <>
      <Row className="align-items-center mx-auto row-container">
        <div className='right-menu'>
          <NavbarLink to="" label={t('home')} />
          <NavbarLink to="become-student" label = "Vreau să fiu elev"/>
          <NavbarLink to="become-teacher" label = "Vreau să fiu profesor"/>
          {!loggedIn && <NavbarLink to="addfunds" label = "Prețuri"/> }
          {loggedIn && <NavbarLink to="lessons" label = "Rezervări"/> }
          {loggedIn && <NavbarLink to="addfunds" label = "Cumpără lecții"/> }
        </div>
        {loggedIn && <div className='right-menu'>
          <Link to="/addfunds">
            <button type="button" className={`navbar-link ${whiteNavbar(isScrolled, location, isMobile) ? 'navbar-link-scrolled' : ''}`}>
              {profileInformation.id > 0 ? profileInformation.lessons.length : ''}{' '}
              <i className="fas far fa-book-open funds-coins" />{' '}
            </button>
          </Link>
          <Link to="/profile">
            <i
              className={`far fa-user fa-2x icon ${whiteNavbar(isScrolled, location, isMobile) ? 'icon-scrolled' : ''}`}
              style={{ color: 'white', paddingRight: 15 }}
            />
          </Link>
          <a
            onClick={() => {
              logout();
            }}
          >
            <i
              className={`fas fa-sign-out-alt fa-2x icon ${whiteNavbar(isScrolled, location, isMobile) ? 'icon-scrolled' : ''}`}
              style={{ color: 'white', paddingLeft: 15 }}
            />
          </a>
        </div> }
      </Row>
      
    </>
  );
};

export default DesktopNavbar;
