import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { isLoggedIn, userState } from '../../atoms/auth';
import { isMobileMenuOpened, isScrolledState, menuDim } from '../../atoms/scrolled';
import { logout } from '../../api/auth/utils';
import './MobileNavbar.css';
import { whiteNavbar } from '../../utils';
import { useMediaQuery } from 'react-responsive';

const MobileNavbar = () => {
  const loggedIn = useRecoilValue(isLoggedIn);
  const [isMenuPressed, setMenuPressed] = useRecoilState(isMobileMenuOpened);
  const [rightDimMenu, setRightDimMenu] = useRecoilState(menuDim);
  const profileInformation = useRecoilValue(userState);
  const { t } = useTranslation('', { keyPrefix: 'navbar' });

  const isScrolled = useRecoilValue(isScrolledState);
  const location = useLocation();
  const isMobile = useMediaQuery({
    query: '(max-width: 500px)',
  });

  function closeMenu() {
    setMenuPressed(!isMenuPressed);
    setRightDimMenu(0);
  }

  return (
    <div>
      <button
        type="button"
        className="d-inline-block site-menu-toggle js-menu-toggle text-black float-right active navbar-button"
        onClick={() => {
          setMenuPressed(!isMenuPressed);

          setRightDimMenu(300);
        }}
      >
        <span className={`fa fa-bars ${whiteNavbar(isScrolled, location, isMobile) ? 'fa-bars-scrolled' : ''}`}/>
        <span className={whiteNavbar(isScrolled, location, isMobile) ? "navbar-menu-text-scrolled" : "navbar-menu-text"}> {t('menu')} </span>
      </button>
      <div className="mobile-navbar mx-auto" style={{ right: rightDimMenu }}>
        <button
          type="button"
          className="d-inline-block site-menu-toggle js-menu-toggle text-black
          float-right active navbar-close-button"
          onClick={() => {
            setMenuPressed(!isMenuPressed);
            setRightDimMenu(0);
          }}
        >
          <span className="fa fa-times" />
        </button>
        <div className="mobile-menu-container">
          <Link to="/#up" className="nav-link cool-link" onClick={closeMenu}>
            Acasă
          </Link>
          <Link to="/become-student" className="nav-link cool-link" onClick={closeMenu}>
            Vreau să fiu elev
          </Link>
          <Link to="/become-teacher" className="nav-link cool-link" onClick={closeMenu}>
            Vreau să fiu profesor
          </Link>
          {!loggedIn && <Link to="/addfunds" className="nav-link cool-link" onClick={closeMenu}>
            Prețuri
          </Link>}

          {loggedIn ? (
            <>
             <Link to="/lessons" className="nav-link cool-link" onClick={closeMenu}>
                Rezervări
               
              </Link>
              <Link to="/addfunds" className="nav-link cool-link" onClick={closeMenu}>
                Cumpără lecții
              
              </Link>

              <Link to="/addfunds" className="nav-link cool-link" onClick={closeMenu} style={{ borderTop: '1px solid white' }}>
                {t('lessons')}: {profileInformation.id > 0 ? profileInformation.lessons.length : ''}{' '}
                <i className="fas far fa-book-open funds-coins" />
              </Link>
              <Link
                to="/profile"
                className="nav-link cool-link"
                
                onClick={closeMenu}
              >
                {t('profile')}
              </Link>

              <button
                type="button"
                className="btn btn-primary logout-btn nav-link"
                onClick={() => {
                  logout();
                }}
              >
                {t('logout')}
              </button>
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default MobileNavbar;
