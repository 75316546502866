import { atom } from 'recoil';

export const isScrolledState = atom<boolean>({
  key: 'isScrolledState',
  default: false,
});

export const isMobileMenuOpened = atom<boolean>({
  key: 'isMobileMenuOpened',
  default: false,
});

export const menuDim = atom<number>({
  key: 'menuDim',
  default: 0,
});
