
import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { ToastContainer } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import Footer from './Footer';
import Navbar from './Navbar';

const Layout: React.FC = ({ children }) => {

  const { pathname } = useLocation();
  const isMobile = useMediaQuery({
    query: '(max-width: 769px)',
  });

  return <>
    <ToastContainer />
    <Navbar />
    {children}
    {(!isMobile || pathname !== '/profile') && <Footer />}
  </>
};

export default Layout;
