import React, { CSSProperties } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import OptimisedImage from '../OptimisedImage';

const styles = {
  socialMediaImage: {
    width: 30,
    height: 30,
    margin: 5,
  } as CSSProperties,
  socialMediaLink: {
    color: 'black',
    textDecoration: 'none',
  } as CSSProperties,
  policyLink: {
    textDecoration: 'none',
    color: '#00d9b2',
  } as CSSProperties,
  policyParagraph: {
    fontSize: '1rem',
  } as CSSProperties,
};

const Footer = () => {
  const { t } = useTranslation('', { keyPrefix: 'footer' });

  return (
    <Row
      className="align-items-start justify-content-around"
      style={{
        width:'100vw',
        paddingBottom: 80,
        paddingTop: 30,
        backgroundColor: 'white',
        paddingLeft: 20,
        paddingRight: 20,
        margin:0
      }}
    >
      <Col md="4">
        <h4 style={{ fontSize: '.9rem' }}>{t('title-1')}</h4>
        <a
          style={styles.socialMediaLink}
          href="https://www.instagram.com/i.to.it/?hl=ro"
          className="d-flex flex-row align-items-center m-0"
        >
          <OptimisedImage
            name='instagram'
            type='png'
            style={styles.socialMediaImage} 
            alt="instagram" 
          />
          <div>i.to.it</div>
        </a>
        <a
          style={styles.socialMediaLink}
          href="https://www.facebook.com/ItoIT.meditatii"
          className="d-flex flex-row p-ai-center m-0"
        >
          <OptimisedImage
            name='facebook'
            type='png'
            style={styles.socialMediaImage} 
            alt="facebook" 
          />
          <div>ItoIT.meditatii</div>
        </a>
        <a
          style={styles.socialMediaLink}
          href="https://blog.itoit.ro/"
          className="d-flex flex-row p-ai-center m-0"
        >
          <OptimisedImage
            name='blog'
            type='png'
            style={styles.socialMediaImage} 
            alt="blog" 
          />
          <div>Blog</div>
        </a>
      </Col>
      <Col md="4">
        <h4 style={{ fontSize: '.9rem' }}>{t('title-2')}</h4>
        <p style={styles.policyParagraph}>{t('description')}</p>
        <p style={styles.policyParagraph} className="mt-1 mb-0">
          {t('text-1')}
        </p>
        <Link style={styles.policyLink} to="/terms">
          <p style={styles.policyParagraph} className="mt-1 mb-0">
            {t('text-2')}
          </p>
        </Link>
        <Link style={styles.policyLink} to="/gdpr">
          <p style={styles.policyParagraph} className="mt-1 mb-0">
            {t('text-3')}
          </p>
        </Link>
      </Col>
    </Row>
  );
};

export default Footer;
