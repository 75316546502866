import React, { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import './Navbar.css';
import { useMediaQuery } from 'react-responsive';
import DesktopNavbar from './DesktopNavbar';
import MobileNavbar from './MobileNavbar';
import OptimisedImage from '../OptimisedImage';
import { isScrolledState } from '../../atoms/scrolled';
import { useRecoilValue } from 'recoil';
import { whiteNavbar } from '../../utils';

const Navbar = () => {
  const {pathname} = useLocation();
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1266px)',
  });
  const isTabletOrMobile = useMediaQuery({
    query: '(max-width: 1265px)',
  });
  const location = useLocation();
  const isMobile = useMediaQuery({
    query: '(max-width: 500px)',
  });
  const isScrolled = useRecoilValue(isScrolledState);

  const getHeaderClass = () => {
    if (isMobile && location.pathname === '/profile') {
      return 'site-navbar-profile'
    }
    if (isScrolled) {
      return 'site-navbar-scrolled'
    }
    return ''
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <header className={`site-navbar ${getHeaderClass()}`}>
      <Container fluid>
        <div className="d-flex align-items-center">
          <Link to="/" className="mr-auto">
            {whiteNavbar(isScrolled, location, isMobile) ? (
              <OptimisedImage 
                name="logo-black" 
                type='png'
                alt="logo" 
                width={isTabletOrMobile ? 85 : 100} 
                height={isTabletOrMobile ? 31 : 37} 
              />
            ) : (
              <OptimisedImage 
                name="logo-white" 
                type='png'
                alt="logo" 
                width={isTabletOrMobile ? 85 : 100} 
                height={isTabletOrMobile ? 31 : 37} 
              />
            )}
          </Link>
          {isDesktopOrLaptop && <DesktopNavbar />}
          {isTabletOrMobile && <MobileNavbar />}
        </div>
      </Container>
    </header>
  );
};

export default Navbar;
