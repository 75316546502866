import React, { useState } from "react";
import Input from '../Input';
import styled from "styled-components";
import BackgroundImage from "../BackgroundImage";
import { makeTeacherRequest } from "../../api/teachers/teachers";

const Container = styled.div`
    color: black;
    background: white;
    padding: 20px 30px 20px 30px;
    margin-top: 2%;
    height: auto !important;
    border-radius: 20px;
    box-shadow: 0 0 10px 3px rgb(0 0 0 / 75%);
    width: 70vw;
    margin-left: 15%;
    @media (max-width:500px){
        padding: 15px 20px 10px 20px;
        margin-top: 25%;
        width: 90vw;
        margin-left:5%;
    }
`;

const SaveButton = styled.button`
  display: block;
  margin: auto;
  color: white;
  border: none;
  border-radius: 8px;
  padding: 8px 20px;
  background-color: #00d9b2;
  height: 50px;
  width: 30%;
  font-size: 14px;
  transition: background-color 0.5s;
  cursor: pointer;

  &:hover {
    background-color: #2eaf98;
    transition: background-color 0.5s;
  }
`;

const TeacherRequest = () => {
  const [saving, setSaving] = useState(false)
  const [calendarLink, setCalendarLink] = useState('');
  const [meetsLink, setMeetsLink] = useState('');
  const [freePlaces, setFreePlaces] = useState('');
  const [studyDescription, setStudyDescription] = useState('');
  const [jobDescription, setJobDescription] = useState('');
  const [facebookUrl, setFacebookUrl] = useState('');
  const [instagramUrl, setInstagramUrl] = useState('');
  const [linkedinUrl, setLinkedinUrl] = useState('');
  const [image, setImage] = useState<File | null>(null);

  const save = () => {
    setSaving(true)
    makeTeacherRequest(calendarLink,
                       meetsLink,
                       freePlaces,
                       studyDescription,
                       jobDescription,
                       facebookUrl,
                       instagramUrl,
                       linkedinUrl,
                       image)
      .then(async (response) => {
        const resp = await response.text();
        alert(resp)
      });
  }

  return (
    <BackgroundImage imageName="wooden-plank4" imageType='jpg' heightFlag={false} hasFilter noScroll={false}>
      <div style={{marginTop:'10%', marginBottom:'10%'}}>
        <Container>
          <h2>
            Formular profesori
          </h2>
          <p>
            Bine ai venit in echipa! Pentru a deveni profesor, urmeaza pasii de mai jos si apoi completeaza formularul:
          </p>
          <p>
            1. Vom avea nevoie de acces la un calendar Google de-al tau ca sa putem sti cand ai liber pentru lectii si sa iti adaugam evenimente.
            Poti folosi calendarul principal asociat mailului tau sau sa creezi unul nou. Intra pe <a href='https://calendar.google.com/calendar/u/0/r' target='_blank'> https://calendar.google.com/calendar/u/0/r</a>
            <li>
              (optional) Pentru a crea un calendar nou, din meniul din stanga "Other calendars" dai click pe "+" si "Create new calendar". Numeste-l cum doresti, preferabil ceva sa contina numele tau. Ex: ItoIT - Mihai Preda
            </li>
            <li>
              Din meniul din stanga "My calendars" pune sageata pe calendarul pe care vrei sa il folosesti (cel creat sau cel principal - Calendar), si dai click pe cele 3 puncte, apoi Settings and sharing.
            </li>
            <li>
              Sub "Share with specific people or groups", click pe "+ Add people and groups" si adauga mail-ul itoitmeditatii@gmail.com
            </li>
            <li>
              (FOARTE IMPROTANT) la permissions, cand adaugi, sa fie "Make changes to events", pentru a putea adauga evenimente.
            </li>
            <li>
              Scroll jos, apoi la "Integrate calendar" gasesti "Calendar ID". Copiaza acest ID in formul de mai jos.
            </li>
            <br/>
            2. Lectiile noastre se desfasoara atat pe Discord, cat si pe Google Meets (la alegerea elevului). Creaza-ti un link de Meets, intrand pe
            <a href='https://meet.google.com/' target='_blank'> https://meet.google.com/</a> -&gt; New Meeting -&gt; Create a meeting for later. Apoi copiaza link-ul in formularul de mai jos. La prima lectie
            cu un elev, va trebui sa il astepti atat pe Meets cat si pe Discord si vezi unde intra. Apoi stabilesti de comun acord cu el unde sa
            desfasurati lectiile.
            <br/>
            3. Completeaza restul formularului.
          </p>
            Link Google Calendar: 
            <Input
              placeholder={'Ex: 9anb35jd5p73o0ga7k9iyd5e80@group.calendar.google.com sau predamihaidragos@gmail.com'}
              setValue={setCalendarLink}
              value={calendarLink}
            />
            Link Google Meets: 
            <Input
              placeholder={'Ex: https://meet.google.com/der-nnfh-gzb'}
              setValue={setMeetsLink}
              value={meetsLink}
            />
            Locuri libere:
            <Input
              placeholder={'Ex: 5'}
              setValue={setFreePlaces}
              value={freePlaces}
            />
            Descriere studii:
            <Input
              placeholder={'Ex: Student în anul 3, Facultatea de Automatică și Calculatoare, Politehnică, București'}
              setValue={setStudyDescription}
              value={studyDescription}
            />
            Descriere job (optional):
            <Input
              placeholder={'Ex: Software Engineer, NumeCompanie'}
              setValue={setJobDescription}
              value={jobDescription}
            />
            Link profil Facebook:
            <Input
              placeholder={'Ex: https://www.facebook.com/profile.php?id=100007531338463'}
              setValue={setFacebookUrl}
              value={facebookUrl}
            />
            Link profil Instagram:
            <Input
              placeholder={'Ex: https://www.instagram.com/bazy.cristi/?hl=ro'}
              setValue={setInstagramUrl}
              value={instagramUrl}
            />
            Link profil LinkedIn (optional):
            <Input
              placeholder={'Ex: https://www.linkedin.com/in/mihai-dragos-preda/'}
              setValue={setLinkedinUrl}
              value={linkedinUrl}
            />
            Poza pentru index: <input 
              type='file'
              accept='image/*'
              onChange={(e) => {
                if (e.target.files) {
                  const file = e.target.files[0]
                  if (file && file.size > 512000) {
                    alert('Image size can not be bigger than 512 KB.');
                    e.target.value = ''
                  } else {
                    setImage(file)
                  }
                }
              }}
            />
            {true && <SaveButton
              type="button"
              className="p-button-rounded p-mb-4 mt-4 save-button"
              onClick={() => save()}
            >
              Save
            </SaveButton>}
        </Container>
      </div>
    </BackgroundImage>)
}

export default TeacherRequest;
