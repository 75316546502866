import { ApiResponse, makeRequest } from "../utils";
import { Teacher } from "../../types/lesson.types";
import { TeamMember } from "../../types/ourTeam.types";


export const getTeachers = async (): Promise<Teacher[]> => {
  const data: ApiResponse<any> = await makeRequest({
    url: "/api/teachers/get_all",
    method: "GET",
  });
  return data.data;
};

export const getAssignedTeachers = async (): Promise<Teacher[]> => {
  const data: ApiResponse<any> = await makeRequest({
    url: "/api/teachers/get_assigned_teachers",
    method: "GET",
  });
  return data.data;
};

export const getTeacherCards = async(): Promise<TeamMember[]> => {
  const data: ApiResponse<any> = await makeRequest({
    url: "/api/teachers/get_teacher_cards",
    method: "GET",
  });
  return data.data;
}

export const makeTeacherRequest = 
  async (calendarLink: string,
         meetsLink: string,
         freePlaces: string,
         studyDescription: string,
         jobDescription: string,
         facebookUrl: string,
         instagramUrl: string,
         linkedinUrl: string,
         image: File | null): Promise<Response> => {

    const formData = new FormData()
    formData.append('calendar_link', calendarLink);
    formData.append('meets_link', meetsLink);
    formData.append('free_places', freePlaces);
    formData.append('study_description', studyDescription);
    formData.append('job_description', jobDescription);
    formData.append('facebook_url', facebookUrl);
    formData.append('instagram_url', instagramUrl);
    formData.append('linkedin_url', linkedinUrl);
    if (image !== null) {
      formData.append('image', image);
    }
    const resp = fetch('/api/teachers/teacher_request', {
        body: formData,
        method: "POST"
    });
    return resp;
}

