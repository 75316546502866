import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import './home/AuthForm.css';
import { Status, ValidationOutput } from './inputValidators';

const styles = {
  errorMessage: {
    color: 'red',
    fontSize: 10,
    margin: '2px 0 10px 0',
  },
};

type InputHandle = {
  validationStatus: () => ValidationOutput;
};

const Input: React.ForwardRefRenderFunction<
  InputHandle,
  {
    value: string;
    setValue: (value: string) => void;
    placeholder: string;
    autoComplete?: string
    secure?: boolean;
    onTouch?: () => void;
    validator?: (data: string) => ValidationOutput;
    error?: boolean;
  }
> = ({ value, validator, setValue, placeholder, autoComplete, secure, error, onTouch }, ref) => {
  const [status, setStatus] = useState<ValidationOutput>({
    data: '',
    message: '',
    status: Status.OK,
  });

  const handleOnChange = (text: string) => {
    let result: ValidationOutput = {
      data: text,
      message: '',
      status: Status.OK,
    };

    if (validator) {
      result = validator(text);
    }

    setStatus(result);
    setValue(text);
  };

  useImperativeHandle(ref, () => ({
    validationStatus() {
      return status;
    },
  }));

  return (
    <div className="d-flex flex-column align-items-start" style={{ width: '100%' }}>
      <InputText
        onTouchEnd={onTouch}
        type={secure ? 'password' : 'text'}
        autoComplete={autoComplete}
        className={`auth-input ${(status.status === Status.ERROR || error) && value && 'auth-input-error'}`}
        style={{
          width: '100%',
          height: 40,
          marginBottom: status.status !== Status.ERROR || value.length === 0 ? 12 : 0,
        }}
        value={value}
        placeholder={placeholder}
        onChange={(e) => {
          handleOnChange((e.target as unknown as HTMLTextAreaElement).value);
        }}
      />

      {validator && value && status.status !== Status.OK && <p style={styles.errorMessage}>{status.message}</p>}
    </div>
  );
};

export default forwardRef(Input);
