export enum Status {
  OK = 'OK',
  WARN = 'WARN',
  ERROR = 'ERROR',
}

export interface ValidationOutput {
  data: string;
  status: Status;
  message: string;
}

export const textFieldValidator = (data: string): ValidationOutput =>
  data.length < 8 || data.length > 35
    ? {
        status: Status.ERROR,
        message: 'Acest câmp trebuie să conțină între 8 și 35 caractere',
        data,
      }
    : {
        status: Status.OK,
        message: '',
        data,
      };

export const notEmptyValidator = (data: string): ValidationOutput =>
  data.length > 0
    ? {
        status: Status.OK,
        message: '',
        data,
      }
    : {
        status: Status.ERROR,
        message: 'Acest câmp nu trebuie să fie gol',
        data,
      };

export const emailValidator = (data: string): ValidationOutput => {
  if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(data)) {
    return {
      data,
      message: '',
      status: Status.OK,
    };
  }
  return {
    data,
    status: Status.ERROR,
    message: 'Adresă de mail invalidă',
  };
};

export const passwordValidator = (data: string): ValidationOutput => {
  const minLength = 6;
  const maxLength = 32;
  // const upperCase = /[A-Z]+/;
  // const number = /[0-9]+/;

  if (data.length < minLength) {
    return {
      message: `Parola trebuie să conțină cel puțin ${minLength} caractere`,
      status: Status.ERROR,
      data,
    };
  }

  if (data.length > maxLength) {
    return {
      message: `Parola nu trebuie să conțină mai mult de ${maxLength} caractere`,
      status: Status.ERROR,
      data,
    };
  }

  // if (!upperCase.test(data)) {
  //   return {
  //     message: "Parola trebuie să conțină cel puțin o literă mare",
  //     status: Status.ERROR,
  //     data,
  //   };
  // }
  //
  // if (!number.test(data)) {
  //   return {
  //     message: "Parola trebuie să conțină cel puțin o cifră",
  //     status: Status.ERROR,
  //     data,
  //   };
  // }

  return {
    data,
    status: Status.OK,
    message: '',
  };
};

export const confirmPasswordValidator = (
  data: string,
  initialPass: ValidationOutput | null,
): ValidationOutput => {
  if (initialPass?.data !== data) {
    return {
      message: 'The passwords do not match',
      status: Status.ERROR,
      data,
    };
  }
  return {
    data,
    status: Status.OK,
    message: '',
  };
};

export const tokenValidator = (data: string): ValidationOutput => {
  if (data.length < 4) {
    return {
      message: 'Token is to short',
      status: Status.ERROR,
      data,
    };
  }

  if (data.length > 4) {
    return {
      message: 'Token is to long',
      status: Status.ERROR,
      data,
    };
  }
  const isValid = /^[0-9]*$/.test(data);
  return {
    data,
    status: isValid ? Status.OK : Status.ERROR,
    message: isValid ? '' : 'In contains invalid characters',
  };
};

export const phoneNumberValidator = (data: string): ValidationOutput => {
  const withPrefix = /(\+[0-9]{11})/;
  const withoutPrefix = /([0-9]{10})/;
  const prefixStatus = withPrefix.test(data);
  const numberStatus = withoutPrefix.test(data);
  if (!numberStatus && !prefixStatus)
    return {
      status: Status.ERROR,
      message: 'Numărul de telefon este invalid',
      data,
    };
  return {
    status: Status.OK,
    message: '',
    data,
  };
};
