/* eslint-disable */
import { ILesson } from '../../types/lesson.types';
import { AdminProfileInformation, ContactUsForm, GetProfileInformation, ProfileInformation, TeacherRequest } from '../../types/profile.types';
import { ApiResponse, makeRequest } from '../utils';
import { User } from '../../types/profile_users.types';
import { Invoice } from '../../types/invoices.types';
import { Feedback } from '../../types/profile.types';

export const getProfileInfo = async (): Promise<GetProfileInformation> => {
  const data = await makeRequest({ url: '/api/auth/get_user', method: 'GET' });
  return data.data;
};

export const setProfileInfo = async (profileInformation: ProfileInformation) => {
  await makeRequest({
    url: '/api/profile/set_info',
    method: 'POST',
    data: profileInformation,
  });
};

export const adminSetProfileInfo = async (adminProfileInformation: AdminProfileInformation) => {
  const data = await makeRequest({
    url: '/api/profile/admin_set_info',
    method: 'POST',
    data: adminProfileInformation,
  });
  return data
};

export const rejectTeacher = async (user_id: number) => {
  const data = await makeRequest({
    url: '/api/teachers/reject_teacher',
    method: 'POST',
    data: {
      user_id: user_id
    },
  });
  return data
};

export const approveTeacher = async (user_id: number, display_card: boolean) => {
  const data = await makeRequest({
    url: '/api/teachers/approve_teacher',
    method: 'POST',
    data: {
      user_id: user_id,
      display_card: display_card
    },
  });
  return data
};

export const adminDeleteUser = async (user_id: number) => {
  const data = await makeRequest({
    url: '/api/auth/delete',
    method: 'POST',
    data: {
      user_id: user_id
    },
  });
  return data
};

export const getMyLessons = async (): Promise<ILesson[]> => {
  const data: ApiResponse<ILesson[]> = await makeRequest({
    url: '/api/lessons/get',
    method: 'GET',
  });
  return data.data;
};

export const getAllTeachersLessons = async (): Promise<ILesson[]> => {
  const data: ApiResponse<ILesson[]> = await makeRequest({
    url: '/api/lessons/get_all',
    method: 'GET',
  });
  return data.data;
};

export const getAllFeedbacks = async (): Promise<Feedback[]> => {
  const data: ApiResponse<Feedback[]> = await makeRequest({
    url: '/api/profile/get_feedbacks',
    method: 'GET',
  });
  return data.data;
};

export const adminAddFeedback = async (feedback: Feedback) => {
  const data = await makeRequest({
    url: '/api/profile/add_feedback',
    method: 'POST',
    data: feedback,
  });
  return data
};

export const sendContactForm = async (form: ContactUsForm) => {
  await makeRequest({
    url: '/api/contact',
    method: 'POST',
    data: form,
  });
};

export const setFeedback = async (lessonId: string, feedback: string) => {
  const resp = await makeRequest({
    url: '/api/lessons/feedback',
    method: 'POST',
    data: {
      lesson_id: lessonId,
      feedback,
    },
  });

  return resp;
};

export const changePassword = async (currentPassword: string, newPassword: string) => {
  const resp = await makeRequest({
    url: '/api/profile/change_password',
    method: 'POST',
    data: {
      current_password: currentPassword,
      new_password: newPassword,
    },
  });
  return resp;
};

export const setSubject = async (lessonId: string, subject: string) => {
  const resp = await makeRequest({
    url: '/api/lessons/subject',
    method: 'POST',
    data: {
      lesson_id: lessonId,
      subject,
    },
  });

  return resp;
};

export const adminRestoreLesson = async (lessonId: string) => {
  const resp = await makeRequest({
    url: '/api/lessons/restore',
    method: 'POST',
    data: {
      lesson_id: lessonId
    },
  });

  return resp;
};

export const extendLesson = async (lessonId: string) => {
  const resp = await makeRequest({
    url: '/api/lessons/extend',
    method: 'POST',
    data: {
      lesson_id: lessonId,
    },
  });

  return resp;
};

export const getUsers = async (): Promise<User[]> => {
  const data: ApiResponse<any> = await makeRequest({
    url: '/api/profile/get_users',
    method: 'GET',
  });
  return data.data.users;
};

export const getAssignedStudents = async (): Promise<User[]> => {
  const data: ApiResponse<any> = await makeRequest({
    url: '/api/profile/get_assigned_students',
    method: 'GET',
  });
  return data.data.users;
};

export const getInvoices = async (): Promise<Invoice[]> => {
  const data: ApiResponse<any> = await makeRequest({
    url: '/api/get_all_bills',
    method: 'GET',
  });
  return data.data.bills;
};

export const getTeacherRequests = async (): Promise<TeacherRequest[]> => {
  const data: ApiResponse<any> = await makeRequest({
    url: '/api/teachers/get_teacher_requests',
    method: 'GET',
  });
  return data.data;
};

export const getFreePlaces = async (): Promise<number> => {
  const data: ApiResponse<any> = await makeRequest({
    url: '/api/profile/get_free_places',
    method: 'GET',
  });
  return data.data.free_places;
};

export const setFreePlaces = async (free_places: number) => {
  const resp = await makeRequest({
    url: '/api/profile/set_free_places',
    method: 'POST',
    data: {
      free_places: free_places,
    },
  });

  return resp;
};
