/* eslint-disable */
import { IProfileInformation } from './types/profile.types';

export const CARD_OPTIONS = {
  iconStyle: 'solid',
  style: {
    base: {
      iconColor: '#c4f0ff',
      color: '#fff',
      fontWeight: 500,
      fontFamily: 'Open Sans, Segoe UI, sans-serif',
      fontSize: '16px',
      fontSmoothing: 'antialiased',
      ':-webkit-autofill': {
        color: '#fce883',
      },
      '::placeholder': {
        color: '#87bbfd',
      },
    },
    invalid: {
      iconColor: '#ffc7ee',
      color: '#ffc7ee',
    },
  },
};

export const isRomanianWebsite = window.location.hostname.endsWith('.ro') ||
  window.location.hostname.includes('localhost');

export const isOwnerEmail = (email: string) => {
  const ownerIds = [
    'bazy_cristi_21@yahoo.com',
    'predamihaidragos@gmail.com'
  ]
  return ownerIds.includes(email)
}

export const isAdminEmail = (email: string) => {
  const adminIds = [
    'bazy_cristi_21@yahoo.com',
    'dorneanuedy@gmail.com',
    'predamihaidragos@gmail.com',
    'cameliacotiga2@gmail.com',
    'blagescu_alex@yahoo.ro',
  ];
  return adminIds.includes(email) || isOwnerEmail(email)
}

export const isAdmin = (user: IProfileInformation) => {
  return isAdminEmail(user.email)
}

export const isOwner = (user: IProfileInformation) => {
  return isOwnerEmail(user.email)
}

export const whiteNavbar = (isScrolled: boolean, location: any, isMobile: boolean) => {
  return isScrolled || (isMobile && location.pathname === '/profile')
}

export const sorted = (
  array: any[], 
  comparator?: ((a: any, b: any) => number)) => {
  let sorted_array = [...array]
  if (comparator) {
    sorted_array.sort(comparator)
  } else {
    sorted_array.sort()
  }

  return sorted_array
}
