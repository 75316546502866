/*eslint-disable*/

import React, { useEffect, lazy, Suspense } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import ReactGA from 'react-ga';
import Layout from './components/layout/Layout';
import 'primereact/resources/themes/md-light-indigo/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeflex/primeflex.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import { isLoggedIn, userState } from './atoms/auth';
import { getProfileInfo } from './api/profile/profile';
import PrivateRoute from './components/PrivateRoute';
import { isScrolledState } from './atoms/scrolled';
import Meta from './components/Meta';
import SuspenseSpinner from './components/SuspenseSpinner';
import { supportsWebp } from './atoms/webp';
import supportsWebP from 'supports-webp';
import TeacherRequest from './components/teacher/TeacherRequest';
const Home = lazy(() => import('./components/home/Home'));
const Profile = lazy(() => import('./components/profile/Profile'));
const Lessons = lazy(() => import('./components/lessons/Lessons'));
const AddFunds = lazy(() => import('./components/addfunds/AddFunds'));
const Terms = lazy(() => import('./components/terms/Terms'));
const Graph = lazy(() => import('./components/graph/Graph'));
const Gdpr = lazy(() => import('./components/gdpr/Gdpr'));
// const Courses = lazy(() => import('./components/courses/Courses'));
// const AddCourses = lazy(() => import('./components/addfunds/AddCourses'));
const BecomeTeacher = lazy(() => import('./components/userType/BecomeTeacher'));
const BecomeStudent = lazy(() => import('./components/userType/BecomeStudent'));

function App() {
  const setIsLoggedIn = useSetRecoilState(isLoggedIn);
  const setUserData = useSetRecoilState(userState);
  const setIsScrolled = useSetRecoilState(isScrolledState);
  const setSupportsWebp = useSetRecoilState(supportsWebp);

  ReactGA.initialize('G-QYVXJ0TEDG');
  ReactGA.pageview(window.location.pathname);

  useEffect(() => {
    window.onscroll = () => {
      if (window.scrollY <= 90) {
        setIsScrolled(false);
      } else {
        setIsScrolled(true);
      }
    };

    supportsWebP.then(supported => {
      setSupportsWebp(supported);
    });

    getProfileInfo()
      .then((values) => {
        if (values.logged_in === 1) {
          setUserData(values.object);
          setIsLoggedIn(true);
        } else {
          setIsLoggedIn(false);
          setUserData({
            id: 0,
            code_force_name: '',
            email: '',
            infoarena_name: '',
            name: '',
            pbinfo_name: '',
            phone_number: '',
            discord: '',
            teacher: 0,
            lessons: [],
            language: '',
            courses: 0,
          });
        }
      })
      .catch((err) => console.log('Err:', err));
    // eslint-disable-next-line
  }, []);

  return (
    <Router>
      <Meta />
      <div>
        <Suspense fallback={<SuspenseSpinner />}>
          <Layout> 
            <Switch>
              <Route path="/" exact>
                <Home />
              </Route>
              <Route path="/terms" exact>
                <Terms />
              </Route>
              <Route path="/gdpr" exact>
                <Gdpr />
              </Route>
              <Route path="/become-teacher" exact>
                <BecomeTeacher />
              </Route>
              <Route path="/become-student" exact>
                <BecomeStudent />
              </Route>
              <Route path="/graph" exact >
                <Graph/>  
              </Route>
              <Route path="/reset/:token">
                {' '}
                <Profile />{' '}
              </Route>
              <Route component={AddFunds} path="/addfunds" exact />
              <PrivateRoute component={Profile} path="/profile" exact />
              <PrivateRoute component={Lessons} path="/lessons" exact />
              <PrivateRoute component={TeacherRequest} path="/teacher-request" exact />
              {/* <PrivateRoute component={Courses} path="/cursuri-admitere" exact /> */}
              {/* <PrivateRoute component={AddCourses} path="/addcourses" exact /> */}
              
              <Route path="">
                <Home />
              </Route>
            </Switch> 
          </Layout>
        </Suspense>  
      </div>
    </Router>
  );
}

export default App;
