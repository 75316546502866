import React from 'react';
import { keyframes } from 'styled-components';
import styled from 'styled-components';

const spinner_size = 2.1;

const animation = keyframes`
  0%, 20%, 80%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
`;

const logoAnimation = keyframes`
  0% {
    opacity: 0.2;
  }
  100% {
    opacity: 0.2;
  }
  50% {
    opacity: 1;
  }
`;

const Logo = styled.div`
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAJoAAAA6BAMAAAC6zV3KAAAAGFBMVEUAAAAGinEAAQAAAQAAAQAXtpoA2rMAAQCu/ENCAAAABnRSTlMAEqlk1zmaRy3QAAABJUlEQVRYw+3XMU+EQBAF4McdcVs7WzpbO1o7WztbK+rxjuT9fYNsdiBwJgzPhKivmiXhy5LN7AR0X8EkCbdSdUPeAXTrWWo1lRqVGpUa/7XDaaf+foeWOMYmq2apee7mT25qvmgEWqlJhcYS268lerSahbT8faW4AtBpZbt7tVS2pNRyJdAg0/hDWi3VcGDtfLhTaHVaNhqZ5kYr6iwq+tSRRnGHvMLGghmzqPYivMkHZDllLgLtoWjPAg0+Y8IaHKiyhpjm3KT8QFBbz1/SOEaktVItN5lt05Kta+cG1fa9JfKb3dkWLb/0tI71ZOBMycsSIy3RAhoeSZtbb6SBxEbNh/E112XGpJA2/+mocjGYFu+FE3N8piKo+Sf2ZVHbUbv+d2ifx6TVJ8w8W54AAAAASUVORK5CYII=');
  display: inline-block;
  position: absolute;
  left: calc(50vw - 40px);
  top: calc(50vh - 16px);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 80px;
  height: 30px;
  animation: ${logoAnimation} 1.2s linear infinite;
  animation-delay: 1.5s;
`;

const SpinnerContainer = styled.div`
  display: inline-block;
  position: absolute;
  left: calc(50vw - ${40*spinner_size}px);
  top: calc(50vh - ${40*spinner_size}px);
  width: 100vw;
  height: 100vh;
  overflow:hidden;
`;

const SpinnerDiv = styled.div`
  position: absolute;
  width: ${6*spinner_size}px;
  height: ${6*spinner_size}px;
  background: #00d9b2;
  border-radius: 50%;
  animation: ${animation} 1.2s linear infinite;
`;

const SpinnerDiv1 = styled(SpinnerDiv)`
  animation-delay: 0s;
  top: ${37*spinner_size}px;
  left: ${66*spinner_size}px;
`;

const SpinnerDiv2 = styled(SpinnerDiv)`
  animation-delay: -0.1s;
  top: ${22*spinner_size}px;
  left: ${62*spinner_size}px;
`;

const SpinnerDiv3 = styled(SpinnerDiv)`
  animation-delay: -0.2s;
  top: ${11*spinner_size}px;
  left: ${52*spinner_size}px;
`;

const SpinnerDiv4 = styled(SpinnerDiv)`
  animation-delay: -0.3s;
  top: ${7*spinner_size}px;
  left: ${37*spinner_size}px;
`;

const SpinnerDiv5 = styled(SpinnerDiv)`
  animation-delay: -0.4s;
  top: ${11*spinner_size}px;
  left: ${22*spinner_size}px;
`;

const SpinnerDiv6 = styled(SpinnerDiv)`
  animation-delay: -0.5s;
  top: ${22*spinner_size}px;
  left: ${11*spinner_size}px;
`;

const SpinnerDiv7 = styled(SpinnerDiv)`
  animation-delay: -0.6s;
  top: ${37*spinner_size}px;
  left: ${7*spinner_size}px;
`;

const SpinnerDiv8 = styled(SpinnerDiv)`
  animation-delay: -0.7s;
  top: ${52*spinner_size}px;
  left: ${11*spinner_size}px;
`;

const SpinnerDiv9 = styled(SpinnerDiv)`
  animation-delay: -0.8s;
  top: ${62*spinner_size}px;
  left: ${22*spinner_size}px;
`;

const SpinnerDiv10 = styled(SpinnerDiv)`
  animation-delay: -0.9s;
  top: ${66*spinner_size}px;
  left: ${37*spinner_size}px;
`;

const SpinnerDiv11 = styled(SpinnerDiv)`
  animation-delay: -1s;
  top: ${62*spinner_size}px;
  left: ${52*spinner_size}px;
`;

const SpinnerDiv12 = styled(SpinnerDiv)`
  animation-delay: -1.1s;
  top: ${52*spinner_size}px;
  left: ${62*spinner_size}px;
`;

const SuspenseSpinner: React.FC<{
  
}> = ({}) => {
  
  return <>
    <Logo />
    <SpinnerContainer>
      <SpinnerDiv1></SpinnerDiv1>
      <SpinnerDiv2></SpinnerDiv2>
      <SpinnerDiv3></SpinnerDiv3>
      <SpinnerDiv4></SpinnerDiv4>
      <SpinnerDiv5></SpinnerDiv5>
      <SpinnerDiv6></SpinnerDiv6>
      <SpinnerDiv7></SpinnerDiv7>
      <SpinnerDiv8></SpinnerDiv8>
      <SpinnerDiv9></SpinnerDiv9>
      <SpinnerDiv10></SpinnerDiv10>
      <SpinnerDiv11></SpinnerDiv11>
      <SpinnerDiv12></SpinnerDiv12>
    </SpinnerContainer>
  </>
};

export default SuspenseSpinner;
