export interface ApiResponse<T> {
  data: T;
  error: string;
}

export async function makeRequest({
  url,
  data,
  method,
}: {
  url: string;
  data?: any;
  method: string;
}): Promise<ApiResponse<any>> {
  const headers = {
    'Content-Type': 'application/json',
  };

  const response = await fetch(url, {
    method,
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers,
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
    body: data ? JSON.stringify(data) : undefined,
  });

  if (response.status >= 200 && response.status < 300) {
    try {
      return { data: await response.json(), error: '' };
    } catch (err) {
      return { data: '', error: '' };
    }
  } else {
    return { data: {}, error: await response.text() };
  }
}

export async function createLocalstorageItem(key: string, value: string) {
  localStorage.setItem(key, value);
  return true;
}
