import React from 'react';
import { Helmet } from 'react-helmet-async';
import { isRomanianWebsite } from '../utils';

const Meta = () =>
  isRomanianWebsite ? (
    <Helmet>
      <meta charSet="utf-8" />
      <link rel="icon" href="/favicon.ico" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="theme-color" content="#000000" />
      <meta
        name="description"
        content="Învață informatică pentru BAC, admitere, concursuri sau examene de la studenți cu
            experiență vastă la companii precum Google sau Bitdefender. Oferim servicii de meditații de
             cea mai înaltă calitate."
      />
      <meta
        name="keywords"
        content="Meditatii, informatica, IT, BAC, admitere, universitate, itoit, i to it, I TO IT,
            ITOIT, bac informatica, pregatire informatica, meditatii informatica itoit, meditatii itoit,
            itoit meditatii, i to it meditatii"
      />

      <link rel="apple-touch-icon" href="/favicon.ico" />

      <link rel="manifest" href="/manifest.json" />
      <link rel="preconnect" href="https://fonts.gstatic.com" />
      <title>I to IT - Pregătire informatică online</title>
    </Helmet>
  ) : (
    <Helmet>
      <meta charSet="utf-8" />
      <link rel="icon" href="/favicon.ico" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="theme-color" content="#000000" />
      <meta
        name="description"
        content="Learn programming from experienced students with vast knowledge
          accumulated at Google or Bitdefender. We offer the highest quality of programming lessons."
      />
      <meta
        name="keywords"
        content="lessons, informatics, IT, computer science, c++, university, itoit, i to it,
            I TO IT, ITOIT, computer science exam, programming lessons, programming lessons itoit,
            lessons itoit, itoit lessons, i to it lessons, learn programming"
      />
      <link rel="apple-touch-icon" href="/favicon.ico" />
      <link rel="manifest" href="/manifest.json" />
      <link rel="preconnect" href="https://fonts.gstatic.com" />
      <title>I to IT - Programming for anyone</title>
    </Helmet>
  );

export default Meta;
